import React from 'react';

import Hero from '../Hero';

import BackdropImage from 'images/gradient-gray.png';
import ProfileImage from 'images/hagemann.jpg';

export default props => (
	// <Hero backgroundImage={}>
	// </Hero>

	<div className="bg-cover bg-center" style={{ backgroundImage: `url(${BackdropImage})` }}>
		<div className="w-full container xl:px-120">
			<div className="pt-120 lg:pt-160 xl:pt-200 pb-60 lg:pb-160 xl:pb-200 relative xl:px-120">
				<h1 className="px-20 lg:px-80 xl:px-120 pb-10 text-left md:text-center">
					{props.title}
				</h1>
				<p className="px-20 lg:px-80 xl:px-120 pt-10 pb-20 text-left md:text-center text-dimgray">
					{props.excerpt}
				</p>
				<div className="px-20 text-xs md:text-sm justify-left md:justify-center text-dimgray pt-20 flex items-center">
					<span className="mr-4">
						<img
							src={ProfileImage}
							alt={props.author}
							className="w-20 h-20 rounded-full"
						/>
					</span>
					<span className="mx-8">{props.author}</span>
					<span>|</span>
					<span className="ml-8">{props.date}</span>
				</div>
			</div>
		</div>
	</div>
);
