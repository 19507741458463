import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

export default ({ src, alt }) => {
	const { allImageSharp } = useStaticQuery(graphql`
		query {
			allImageSharp {
				edges {
					node {
						fluid(maxWidth: 500, quality: 60) {
							...GatsbyImageSharpFluid
							originalName
						}
					}
				}
			}
		}
	`);
	const image = allImageSharp.edges.find(edge => edge.node.fluid.originalName === src);
	if (!image) {
		return null;
	}
	return (
		<div className="py-40">
			<Img fluid={image.node.fluid} alt={alt} />
		</div>
	);
};
