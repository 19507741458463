import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

export default ({ src, alt, className, children }) => {
	const { allImageSharp } = useStaticQuery(graphql`
		query {
			allImageSharp {
				edges {
					node {
						fluid(maxWidth: 500, quality: 60) {
							...GatsbyImageSharpFluid
							originalName
						}
					}
				}
			}
		}
	`);
	const image = allImageSharp.edges.find(edge => edge.node.fluid.originalName === src);
	if (!image) {
		return null;
	}
	return (
		<div className="flex flex-wrap flex-wrap-reverse items-center py-40 h-450">
			<div className="w-full lg:w-1/2 px-20 lg:pr-20 lg:pl-0">
				<p className="mt-40 lg:mt-0 mr-160">{children}</p>
			</div>
			{/* w-full lg:w-1/2 px-20 lg:pl-20 lg:pr-0 */}
			<div className="w-550">
				<Img fluid={image.node.fluid} alt={alt} className={className} />
			</div>
		</div>
	);
};
