import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';

import SEO from '../components/seo';

import ArticleHeader from '../components/Blog/ArticleHeader';
import LargeImage from '../components/Blog/LargeImage';
import LeftImage from '../components/Blog/LeftImage';
import RightImage from '../components/Blog/RightImage';

import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

const Header = props => <h3 className="px-20 xl:px-240 mt-40 mb-16" {...props} />;
const Paragraph = props => <p className="px-20 xl:px-240 mb-16" {...props} />;
const BlockQuote = props => (
	<blockquote className="font-medium my-50 md:my-60 xl:my-80" {...props} />
);

const shortcodes = {
	h1: Header,
	h2: Header,
	h3: Header,
	h4: Header,
	h5: Header,
	h6: Header,
	p: Paragraph,
	blockquote: BlockQuote,
	Link,
	LargeImage,
	LeftImage,
	RightImage,
};

const singlePost = ({ data }) => {
	const featureImage =
		data.mdx.frontmatter.featureImage !== null
			? data.mdx.frontmatter.featureImage.childImageSharp.fluid
			: null;

	return (
		<Layout>
			<SEO title={data.mdx.frontmatter.title} description={data.mdx.frontmatter.excerpt} />
			<ArticleHeader
				title={data.mdx.frontmatter.title}
				excerpt={data.mdx.frontmatter.excerpt}
				author={data.mdx.frontmatter.author}
				date={data.mdx.frontmatter.date}
			/>
			<article className="w-full container -mt-20 lg:-mt-100 xl:-mt-120 mb-120 px-0 lg:px-120">
				{featureImage && <Img fluid={featureImage} className="md:rounded h-500" />}
				<div className="mt-40 lg:pt-40 bg-white">
					<MDXProvider components={{ ...shortcodes }}>
						<MDXRenderer>{data.mdx.body}</MDXRenderer>
					</MDXProvider>
				</div>
			</article>
		</Layout>
	);
};

export default singlePost;

export const pageQuery = graphql`
	query SinglePostQuery($id: String!) {
		mdx(id: { eq: $id }) {
			frontmatter {
				date(formatString: "MMMM DD, YYYY")
				excerpt
				slug
				title
				author
				featureImage {
					id
					childImageSharp {
						fluid(quality: 90) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
			body
		}
	}
`;
